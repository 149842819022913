import { lazy, mount } from 'navi';
import { Suspense } from 'react';
import { Router, View } from 'react-navi';
import { useT } from '../hooks';
import { Layout } from '../layout';
import { AppTreeView, AppTreeViewProvider } from '../models/AppTreeView';
import { ICONS } from '../theme/icons';
import { NotFound } from './NotFound';

const routes = {
  '*': lazy(() => import('../pages/distributorSAV')),
};

export const DistributorSAVRouter = () => {
  const t = useT();

  const appTreeView = AppTreeView.create({
    items: [
      {
        title: t('Devices management'),
        children: [
          {
            title: t('Medical devices'),
            Icon: ICONS.MedicalDevice,
            path: '/medical-devices/list',
          },
        ],
      },
      {
        title: t('Incident management'),
        children: [
          {
            title: t('Incident list'),
            Icon: ICONS.Incident,
            path: '/incidents/list',
          },
          {
            title: t('New incident'),
            Icon: ICONS.Add,
            path: '/incidents/create',
          },
        ],
      },
      {
        title: t('Order management'),
        children: [{
          title: t('Order list'),
          Icon: ICONS.Order,
          path: '/orders/list'
        }, {
          title: t('Create order'),
          Icon: ICONS.Add,
          path: '/orders/create'
        }]
      }
    ],
  });
  return (
    <Router routes={mount(routes)}>
      <AppTreeViewProvider value={appTreeView}>
        <Layout renderNotFound={(err) => NotFound(err, t)}>
          <Suspense fallback={null}>
            <View />
          </Suspense>
        </Layout>
      </AppTreeViewProvider>
    </Router>
  );
};
