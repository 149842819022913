import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar, Box, Button, Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { ConfirmAction } from '../components/ConfirmAction';
import { ehValue } from '../components/utils/eventHandler';
import { SAV_UI_URL } from '../globals';
import { useGoto } from '../hooks/useGoto';
import { useT } from '../hooks/useT';
import { RoleType, useUserStore } from '../models/User';
import { ICONS } from '../theme/icons';

export const AccountMenu = () => {
  const t = useT();
  const userStore = useUserStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const auth0 = useAuth0();
  const goto = useGoto();
  const [roleValue, setRoleValue] = useState<RoleType | null>(
    userStore.selectedRole
  );

  const handleLogout = () => {
    setAnchorEl(null);
    auth0.logout({ returnTo: SAV_UI_URL });
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmChangeRole = () => {
    userStore.selectRole(roleValue);
    goto('/')();
  };

  return (
    <>
      
      <Button color='inherit' onClick={handleMenu} sx={{ ml: 2 }} startIcon={<Avatar sx={{ bgcolor: 'white', width: 32, height: 32 }}>
          <ICONS.Account color="primary" />
        </Avatar>} >
        
        {userStore.selectedRole ? t(userStore.selectedRole) : ''}
      </Button>
     

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListItemIcon>
            <Avatar sx={{ bgcolor: 'white', width: 32, height: 32 }}>
              <ICONS.Account color="primary" />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            secondary={userStore.selectedRole ? t(userStore.selectedRole) : ''}
            primary={auth0.user?.name}
          />
        </MenuItem>
        <Divider />
        {userStore.roles.length > 1 && (
          <ConfirmAction
            render={(props) => (
              <MenuItem {...props}>
                <ListItemIcon>
                  <ICONS.ManageAccounts />
                </ListItemIcon>
                <ListItemText primary={t('Choose role')} />
              </MenuItem>
            )}
            confirmTitle={t('Choose role')}
            confirmContent={
              <Box sx={{ padding: 2 }}>
                <TextField
                  id="role-select"
                  value={roleValue}
                  select
                  label={t('Role')}
                  onChange={ehValue(setRoleValue)}
                  variant="outlined"
                >
                  {userStore.roles.map((role: RoleType) => {
                    return (
                      <MenuItem value={role} key={role}>
                        {t(role)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            }
            onConfirm={handleConfirmChangeRole}
            onCancel={() => setRoleValue(userStore.selectedRole)}
          />
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ICONS.Logout />
          </ListItemIcon>
          <ListItemText>{t('Logout')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
