import React from 'react';
import {
  Avatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Fr, Gb } from 'react-flags-select';
import { IconButton } from '../components/IconButton';
import moment from 'moment';

const languages = [
  { name: 'en', icon: <Gb />, description: 'English' },
  { name: 'fr', icon: <Fr />, description: 'Français' },
];

export const I18nMenu = () => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => () => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
  };

  return (
    <>
      <IconButton
        tooltip={
          languages.find((l) => l.name === i18n.language)?.description || ''
        }
        onClick={handleMenu}
      >
        <Avatar sx={{ bgcolor: 'white', width: 32, height: 32 }}>
          {languages.find((l) => l.name === i18n.language)?.icon}
        </Avatar>
      </IconButton>
      <Menu
        id="language-menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((l) => (
          <MenuItem
            key={l.name}
            selected={l.name === i18n.language}
            onClick={changeLanguage(l.name)}
          >
            <ListItemIcon>{l.icon}</ListItemIcon>
            <ListItemText>{l.description}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
