import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { SAV_API_URL } from '../globals';

import { useUserStore } from '../models/User';

export const GraphqlProvider = (props: { children: React.ReactNode, onError: (error: ErrorResponse) => void }) => {
  const userStore = useUserStore();
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      onError((error: ErrorResponse) => {
        if (error.graphQLErrors)
          error.graphQLErrors.forEach((gError) => {
            console.log(
              `[GraphQL error]: Message: ${gError.message}, Location: ${gError.locations}, Path: ${gError.path}`
            , gError);
            
          });
        if (error.networkError) {
          console.log(`[Network error]: ${error.networkError}`, error.networkError);
        }
        props.onError(error);
      }),
      new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            Authorization: `Bearer ${userStore.getAccessToken()}`,
            'X-Role': userStore.selectedRole,
          },
        }));

        return forward(operation);
      }),
      createUploadLink({
        uri: SAV_API_URL,
      }),
    ]),
  });
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
