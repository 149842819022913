import { AppBar, Toolbar, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLayoutStore } from '../models/LayoutStore';
import { AccountMenu } from './AccountMenu';
import { I18nMenu } from './I18nMenu';

export const TopBar = observer(() => {
  const layoutStore = useLayoutStore();
  const { drawerWidth } = layoutStore;

  return (
    <AppBar
      color="secondary"
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        bgcolor: 'grey.750',
        height: 44,
        p: 0,
      }}
    >
      <Toolbar
        disableGutters
        sx={{ height: '44px', minHeight: '44px', paddingLeft: 2 }}
        variant="dense"
      >
        {layoutStore.title}

        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        <I18nMenu />
        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
});
