import React from 'react';

import { NotFoundError } from 'navi';
import { NotFoundBoundary } from 'react-navi';

import { Box, Toolbar } from '@mui/material';

import { LayoutProvider, layoutStore } from '../models/LayoutStore';
import { LeftMenu } from './LeftMenu';
import { TopBar } from './TopBar';

export type LayoutProps = {
  children: React.ReactNode;
  renderNotFound: (error: NotFoundError) => React.ReactNode;
};

function Layout(props: LayoutProps) {
  const { children, renderNotFound } = props;
  return (
    <React.Fragment>
      <LayoutProvider value={layoutStore}>
        <Box sx={{ display: 'flex' }}>
          <TopBar />
          <LeftMenu />
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
          >
            <Toolbar />
            <NotFoundBoundary render={renderNotFound}>
              {children}
            </NotFoundBoundary>
          </Box>
        </Box>
      </LayoutProvider>
    </React.Fragment>
  );
}

export { Layout };
