export const SAV_API_URL = process.env.REACT_APP_SAV_API_URL || '';
export const SAV_DOCUMENT_API_URL =
  process.env.REACT_APP_SAV_DOCUMENT_URL || '';

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
export const ROLE_NAMESPACE = process.env.REACT_APP_ROLE_NAMESPACE || '';
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || '';
export const SAV_UI_URL = process.env.REACT_APP_SAV_UI_URL || '';

export const WARRANTY_MIN_MAX = {
  min: 1,
  max: 120
}
console.log(process.env);
