import { lazy, mount } from 'navi';
import React, { Suspense } from 'react';
import { Router, View } from 'react-navi';
import { useT } from '../hooks/useT';
import { Layout } from '../layout';
import { AppTreeView, AppTreeViewProvider } from '../models/AppTreeView';
import { ICONS } from '../theme/icons';
import { NotFound } from './NotFound';

const routes = {
  '*': lazy(() => import('../pages/salesAdmin')),
};

export const SalesAdminRouter = () => {
  const t = useT();

  const appTreeView = AppTreeView.create({
    items: [
      {
        title: t('Distributor management'),
        children: [
          {
            title: t('Distributors'),
            Icon: ICONS.Distributor,
            path: '/distributors/list',
          },
          {
            title: t('Add distributor'),
            Icon: ICONS.AddDistributor,
            path: '/distributors/add',
          },
          {
            title: t('Import from CSV'),
            Icon: ICONS.Document,
            path: '/distributors/import-csv',
          },
        ],
      },
      {
        title: t('Devices management'),
        children: [
          {
            title: t('Medical devices'),
            Icon: ICONS.MedicalDevice,
            path: '/medical-devices/list',
          },
        ],
      },
      {
        title: t('Order management'),
        children: [
          {
            title: t('Order list'),
            Icon: ICONS.Order,
            path: '/orders/list',
          },
        ],
      },
    ],
  });
  return (
    <Router routes={mount(routes)}>
      <AppTreeViewProvider value={appTreeView}>
        <Layout renderNotFound={(err) => NotFound(err, t)}>
          <Suspense fallback={null}>
            <View />
          </Suspense>
        </Layout>
      </AppTreeViewProvider>
    </Router>
  );
};
