import { ErrorResponse } from '@apollo/client/link/error';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { CssBaseline } from '@mui/material';
import { enUS as muiEnUS, frFR as muiFrFR } from '@mui/material/locale';
import {
  enUS as muiDataGridEnUS,
  frFR as muiDataGridFrFR
} from '@mui/x-data-grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationProvider } from './auth/AuthenticationProvider';
import { GraphqlProvider } from './graphql/GraphqlProvider';
import { useT } from './hooks';
import { AppRouter } from './router';
import ThemeConfig from './theme';

const muiLocales: any = {
  en: [muiDataGridEnUS, muiEnUS],
  fr: [muiDataGridFrFR, muiFrFR],
};

const GraphqlProviderWithErrorHandler = (props: {
  children: React.ReactNode;
}) => {
  const t = useT();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = (error: ErrorResponse) => {
    if (error.graphQLErrors) {
      error.graphQLErrors.forEach(({ message, extensions }) => 
        enqueueSnackbar(t(message, extensions?.exception as any), { variant: 'error' })
      );
    }
    if (error.networkError) {
      enqueueSnackbar(error.networkError.message, { variant: 'error' });
    }
  };
  return (
    <GraphqlProvider onError={handleError}>{props.children}</GraphqlProvider>
  );
};

function App() {
  const { i18n } = useTranslation();

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeConfig locales={muiLocales[i18n.language]}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <AuthenticationProvider>
            <SnackbarProvider maxSnack={3}>
              <GraphqlProviderWithErrorHandler>
                <AppRouter />
              </GraphqlProviderWithErrorHandler>
            </SnackbarProvider>
          </AuthenticationProvider>
        </LocalizationProvider>
      </ThemeConfig>
    </React.Fragment>
  );
}

export default App;
