import { Instance, types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';

export const ROLES = [
  'scaleo-industries',
  'sales-admin',
  'portal-admin',
  'distributor-sav',
  'scaleo-sav',
] as const;

export type RoleType = typeof ROLES[number];

const UserModel = types
  .model({
    roles: types.array(types.enumeration([...ROLES])),
    selectedRole: types.maybeNull(types.enumeration([...ROLES])),
    accessToken: types.maybeNull(types.string),
  })

  .actions((self) => ({
    selectRole(newRole: RoleType | null) {
      if (newRole && self.roles.includes(newRole)) {
        self.selectedRole = newRole;
        window.localStorage.setItem('lastRole', newRole);
      } else {
        self.selectedRole = null;
      }
    },
    setAccessToken(v: string) {
      self.accessToken = v;
    },
  }))
  .views((self) => ({
    getAccessToken() {
      return self.accessToken;
    },
  }));

export function createUserStore(roles: RoleType[]) {
  const selectedRole: any = window.localStorage.getItem('lastRole') || roles[0];
  const result = UserModel.create({
    roles,
    selectedRole: null,
    accessToken: null,
  });
  result.selectRole(roles.includes(selectedRole) ? selectedRole : roles[0]);
  return result;
}
export type UserInstance = Instance<typeof UserModel>;

const UserStoreContext = createContext<null | UserInstance>(null);

export const UserProvider = UserStoreContext.Provider;
export function useUserStore() {
  const store = useContext(UserStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
