import { gql, useQuery } from '@apollo/client';
import { FragmentConfig, QueryConfig } from './types';


function buildQuery(queryConfig: QueryConfig<unknown, unknown, unknown>) {
  const recName = (fragments?: FragmentConfig<unknown, unknown>[]): string => {
    if (!fragments || !fragments.length) {
      return '';
    }
    return `WITH_${fragments
      .map((f) => `${f.query.name}_${recName(f.fragments)}`)
      ?.join('_')}_EWITH`;
  };

  const fragmentFields = (
    fragments?: FragmentConfig<unknown, unknown>[]
  ): string => {
    if (!fragments || !fragments.length) {
      return '';
    }
    return fragments.map((f) => `...${f.query.name}_${f.query.on}`).join('\n');
  };

  const recFragments = (
    fragments?: FragmentConfig<unknown, unknown>[]
  ): string => {
    if (!fragments || !fragments.length) {
      return '';
    }
    return `
        ${fragments
          .map(
            (f) => `
           fragment ${f.query.name}_${f.query.on} on ${f.query.on} {
            ${f.query.fields.replace(
              /\{\{ *fragments *\}\}/,
              fragmentFields(f.fragments)
            )}
           }
        `
          )
          .join('\n')}

        ${fragments.map((f) => recFragments(f.fragments)).join('\n')}
    
    `;
  };
  const result = `
    ${recFragments(queryConfig.fragments)}

    ${queryConfig.query.template
      .replace(
        /\{\{ *name *\}\}/,
        `${queryConfig.query.name}_${recName(queryConfig.fragments)}`
      )
      .replace(/\{\{ *fragments *\}\}/, fragmentFields(queryConfig.fragments))}
  `;
  try {
    return gql(result);
  } catch (err) {
    console.log(result);
    throw new Error(`Error in graphql query`);
  }
}

export function useQ<VariableT, ResultT>(
  q: QueryConfig<unknown, VariableT, ResultT>,
  variables?: VariableT,
  options?: any
) {
  return useQuery<ResultT>(buildQuery(q), { variables, ...options });
  
}
