import { useNavigation } from 'react-navi';

export function useGoto() {
  const navigation = useNavigation();
  return (url: 'back' | string, replace?: boolean) => () => {
    if (url === 'back') {
      navigation.goBack();
    } else if (url.startsWith('/')) {
      navigation.navigate(url, { replace });
    } else {
      throw new Error(`Invalid url ${url}`);
    }
  };
}
