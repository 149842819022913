import React from 'react';
import './css.css';

export const Loader = () => {
  return (
    <ul
      style={{
        margin: 0,
        listStyle: 'none',
        width: '90px',
        position: 'relative',
        padding: 0,
        height: '10px',
      }}
    >
      <li
        style={{
          left: 0,
          animation: 'sequence1 1s ease infinite 0',
          backgroundColor: '#3d3d3d',
          position: 'absolute',
          width: '2px',
          height: 0,
          bottom: 0,
        }}
      ></li>
      <li
        style={{
          left: '15px',
          animation: 'sequence2 1s ease infinite 0.1s',
          backgroundColor: '#3d3d3d',
          position: 'absolute',
          width: '2px',
          height: 0,
          bottom: 0,
        }}
      ></li>
      <li
        style={{
          left: '30px',
          animation: 'sequence1 1s ease-in-out infinite 0.2s',
          backgroundColor: '#3d3d3d',
          position: 'absolute',
          width: '2px',
          height: 0,
          bottom: 0,
        }}
      ></li>
      <li
        style={{
          left: '45px',
          animation: 'sequence2 1s ease infinite 0.3s',
          backgroundColor: '#3d3d3d',
          position: 'absolute',
          width: '2px',
          height: 0,
          bottom: 0,
        }}
      ></li>
      <li
        style={{
          left: '60px',
          animation: 'sequence1 1s ease infinite 0.4s',
          backgroundColor: '#3d3d3d',
          position: 'absolute',
          width: '2px',
          height: 0,
          bottom: 0,
        }}
      ></li>
      <li
        style={{
          left: '75px',
          animation: 'sequence2 1s ease infinite 0.5s',
          backgroundColor: '#3d3d3d',
          position: 'absolute',
          width: '2px',
          height: 0,
          bottom: 0,
        }}
      ></li>
    </ul>
  );
};
