import React from 'react';

import { observer } from 'mobx-react-lite';
import { useNavigation } from 'react-navi';
import logo from '../assets/myhorizon-logo.png';

import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Box,
  Typography,
  TypographyProps,
} from '@mui/material';

import { useGoto } from '../hooks/useGoto';
import { useAppTreeViewStore } from '../models/AppTreeView';
import { useLayoutStore } from '../models/LayoutStore';

const MenuItemTitle = (props: TypographyProps) => (
  <Typography variant="overline" display="block" gutterBottom {...props} />
);

export const LeftMenu = observer(() => {
  const appTreeView = useAppTreeViewStore();
  const layoutStore = useLayoutStore();
  const { drawerWidth } = layoutStore;
  const navigation = useNavigation();
  const goto = useGoto();

  if (appTreeView.items.length) {
    return (
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              onClick={goto('/')}
              sx={{ pl: 1, pr: 1, alignItems: 'center' }}
              color="secondary"
            >
              <img  height="87" src={logo} alt="myHorizon" />
            </Button>
          </Box>
        </Toolbar>

        {appTreeView.items.map((item) => (
          <React.Fragment key={item.title}>
            <List>
              <ListItem>
                <ListItemText
                  primary={<MenuItemTitle>{item.title}</MenuItemTitle>}
                />
              </ListItem>
              {item.children &&
                item.children.map((subItem) => (
                  <ListItemButton
                    key={subItem.title}
                    selected={subItem.isSelected(
                      navigation.getCurrentValue().url.pathname
                    )}
                    onClick={goto(subItem.path, false)}
                  >
                    {subItem.Icon && (
                      <ListItemIcon>
                        <subItem.Icon />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={subItem.title} />
                  </ListItemButton>
                ))}
            </List>
            <Divider />
          </React.Fragment>
        ))}
      </Drawer>
    );
  } else {
    return null;
  }
});
