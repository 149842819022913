import React from 'react';

import { observer } from 'mobx-react-lite';

import { RoleType, useUserStore } from '../models/User';

import { ScaleoIndustriesRouter } from './ScaleoIndustriesRouter';
import { SalesAdminRouter } from './SalesAdminRouter';
import { PortalAdminRouter } from './PortalAdminRouter';
import { DistributorSAVRouter } from './DistributorSAVRouter';
import { ScaleoSAVRouter } from './ScaleoSAVRouter';
import { useApolloClient } from '@apollo/client';

const routers: { [key in RoleType]: any } = {
  'scaleo-industries': ScaleoIndustriesRouter,
  'sales-admin': SalesAdminRouter,
  "portal-admin": PortalAdminRouter,
  "distributor-sav": DistributorSAVRouter,
  "scaleo-sav": ScaleoSAVRouter
};

const AppRouter = observer(() => {
  const userStore = useUserStore();
  
  if (userStore.selectedRole) {
    const client = useApolloClient();
    client.resetStore();
    const Router = routers[userStore.selectedRole];

    return <Router />;
  } else {
    return null;
  }
});

export { AppRouter };
