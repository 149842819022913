import { useState } from 'react';

export function useStateWithError<T>(
  defaultValue: T
): [T, (v: T) => void, string | null, (error: string) => void] {
  const [value, setValue] = useState(defaultValue);
  const [errorText, setErrorText] = useState<null | string>(null);
  function setStateValue(v: T) {
    setValue(v);
    setErrorText(null);
  }

  return [value, setStateValue, errorText, setErrorText];
}
