import { Alert, AlertTitle } from "@mui/material";
import React from "react";



function NotFound(err: Error, t:any) {
  
  return (
    <Alert severity="error">
      <AlertTitle>{t("Page not found")}</AlertTitle>
    </Alert>
  );
}

export { NotFound };
