import { useAuth0 } from '@auth0/auth0-react';
import { Backdrop, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import logo from '../../assets/scaleo-logo.png';
import { useT } from '../../hooks/useT';

export const NotLogguedPage = () => {
  const t = useT();
  const auth0 = useAuth0();

  useEffect(() => {
    const tout = setTimeout(() => {
      auth0.loginWithRedirect();
    }, 1500);
    return () => tout && clearTimeout(tout);
  });

  return (
    <Backdrop open={true}>
      <Paper sx={{ padding: 2 }}>
        <Stack
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src={logo} alt="Logo" />
          <Typography variant="subtitle2">{t('Loading')}...</Typography>
        </Stack>
      </Paper>
    </Backdrop>
  );
};
