import React from "react";
import ReactDOM from "react-dom";

import "./i18n";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
// This function handles any unhandled promise rejections

const globalPromiseRejectionHandler = (event:PromiseRejectionEvent) => {

  console.log('Unhandled promise rejection reason: ', event.reason);
  event.stopImmediatePropagation();
  event.stopPropagation();
  event.preventDefault();
}


// Here we assign our handler to the corresponding global, window property

window.onunhandledrejection = globalPromiseRejectionHandler;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
