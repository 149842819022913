import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import { initReactI18next } from "react-i18next";

import 'moment/locale/fr';


import enLocales from "./locales/en";
import frLocales from "./locales/fr";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: enLocales,
      fr: frLocales,
    },
    ns: ["common", "glossary"],
    lng: localStorage.getItem("i18nextLng") || "en",
    fallbackLng: "en",
    defaultNS: "common",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

moment.locale(localStorage.getItem("i18nextLng") || "en")

export default i18n;
