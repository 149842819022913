import { Instance, types } from 'mobx-state-tree';

import { createContext, useContext } from 'react';

export const LayoutModel = types
  .model({
    drawerWidth: types.number,
    // title: types.maybeNull(types.string),
  })
  .volatile(self => ({
    title: null,
  }))
  .actions((self) => ({
    setTitle(title: any) {
      self.title = title;
    },
  }));

const initialState = LayoutModel.create({
  drawerWidth: 240,
});

export const layoutStore = initialState;

export interface LayoutInstance extends Instance<typeof LayoutModel> {}

const LayoutStoreContext = createContext<null | LayoutInstance>(null);

export const LayoutProvider = LayoutStoreContext.Provider;

export function useLayoutStore() {
  const store = useContext(LayoutStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
