import { useMemo, ReactNode } from 'react';
// material
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';

//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { merge } from 'lodash';
// ----------------------------------------------------------------------

type ThemeConfigProps = {
  children: ReactNode;
  locales: any;
};

export default function ThemeConfig({ children, locales }: ThemeConfigProps) {
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: { ...palette.light, mode: 'light' },
      shape,
      typography,
      breakpoints,

      shadows: shadows.light,
      customShadows: customShadows.light,
      locales,
    }),
    [locales]
  );

  const theme = createTheme(themeOptions);
  //console.log('T1', theme);
  theme.components = merge(
    componentsOverride(theme),
    ...locales.map((l: any) => l.components)
  );
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
