import {
  IconButtonProps,
  Tooltip,
  IconButton as MuiIconButton,
} from '@mui/material';

export const IconButton = (props: IconButtonProps & { tooltip: string }) => {
  const { tooltip, ...iconButtonProps } = props;
  return (
    <Tooltip title={props.tooltip}>
      <MuiIconButton {...iconButtonProps} />
    </Tooltip>
  );
};
