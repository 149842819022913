import React, { MouseEventHandler, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useT } from '../hooks/useT';
import { ICONS } from '../theme/icons';


export type ConfirmActionProps = {
  confirmTitle: React.ReactNode;
  confirmContent?: React.ReactNode;
  confirmCancelLabel?: React.ReactNode;
  confirmOkLabel?: React.ReactNode;
  render: (renderProps: {
    onClick: MouseEventHandler<unknown>;
  }) => React.ReactNode;
  onConfirm: MouseEventHandler<unknown>;
  onCancel?: MouseEventHandler<unknown>;
};

export const ConfirmAction = (props: ConfirmActionProps) => {
  const {
    confirmTitle,
    confirmContent,
    confirmCancelLabel,
    confirmOkLabel,
    render,
    onConfirm,
    onCancel,
  } = props;

  const t = useT();

  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleClose = (e: any) => {
    setOpen(false);
    onCancel && onCancel(e);
  };

  const handleOkClick = (e: any) => {
    onConfirm(e);
    setOpen(false);
  };

  return (
    <>
      {render({ onClick: handleButtonClick })}
      <Dialog open={open} keepMounted={false}>
        <DialogTitle>{confirmTitle}</DialogTitle>
        {confirmContent && <DialogContent>{confirmContent}</DialogContent>}
        <DialogActions>
          <Button onClick={handleClose} color="secondary" startIcon={<ICONS.Cancel/>}>
            {confirmCancelLabel || t('Cancel')}
          </Button>
          <Button onClick={handleOkClick} autoFocus variant="contained" startIcon={<ICONS.Check/>}>
            {confirmOkLabel || t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
