import React from 'react';
import { useT } from '../hooks/useT';
import { useAuth0 } from '@auth0/auth0-react';
import { get } from 'lodash';
import { ROLE_NAMESPACE } from '../globals';
import { Alert, AlertTitle } from '@mui/material';
import { createUserStore, UserProvider } from '../models/User';
import { FullscreenLoader } from '../components/FullscreenLoader';
import { NotLogguedPage } from '../pages/notLogguedPage';

export const IsUserLogged = (props: { children: React.ReactNode}) => {
  const t = useT();
  const auth0 = useAuth0();

  if (auth0.isAuthenticated) {
    const roles = get(auth0, ['user', ROLE_NAMESPACE]) || [];
    if (!roles.length) {
      return (
        <Alert variant="filled" severity="error">
          <AlertTitle>
            {t(
              "User don't have any role, please contact system administrator.",
              { name: auth0.user?.name }
            )}
          </AlertTitle>
        </Alert>
      );
    }
    const userStore = createUserStore(roles);
    return (
      <UserProvider value={userStore}>
          {props.children}
      </UserProvider>
    );
  } else if (auth0.isLoading) {
    return <FullscreenLoader open={true} />;
  } else {
    return <NotLogguedPage />;
  }
};
