import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FullscreenLoader } from '../components/FullscreenLoader';
import { AUTH0_AUDIENCE } from '../globals';
import { useUserStore } from '../models/User';

export const AccessTokenLoader = observer(
  (props: { children: React.ReactNode }) => {
    const auth0 = useAuth0();
    const { setAccessToken, accessToken } = useUserStore();
    useEffect(() => {
      const getUserMetadata = async () => {
        try {
          const audience = AUTH0_AUDIENCE;
          const accessToken = await auth0.getAccessTokenSilently({
            audience,
            scope: 'read:current_user',
          });

          setAccessToken(accessToken);
        } catch (err) {
          console.log(err);
        }
      };
      if (auth0.isAuthenticated) {
        getUserMetadata();
      }
    }, [auth0, auth0.getAccessTokenSilently, auth0.user?.sub, setAccessToken]);

    if (auth0.isAuthenticated && !accessToken) {
      return <FullscreenLoader open={true} />;
    }
    return <>{props.children}</>;
  }
);
