import React from 'react';
import { Backdrop } from '@mui/material';
import { Loader } from './Loader';

export const FullscreenLoader = (props: { open: boolean }) => {
  return (
    <Backdrop
      
      open={props.open}
    >
      <Loader />
    </Backdrop>
  );
};
