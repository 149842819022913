import { lazy, mount } from 'navi';
import { Suspense } from 'react';
import { Router, View } from 'react-navi';
import { useT } from '../hooks';
import { Layout } from '../layout';
import { AppTreeView, AppTreeViewProvider } from '../models/AppTreeView';
import { ICONS } from '../theme/icons';
import { NotFound } from './NotFound';

const routes = {
  '*': lazy(() => import('../pages/portalAdmin')),
};

export const PortalAdminRouter = () => {
  const t = useT();

  const appTreeView = AppTreeView.create({
    items: [
      {
        title: t('User management'),
        children: [
          {
            title: t('Users'),
            Icon: ICONS.Users,
            path: '/users/list',
          },
          {
            title: t('Create user'),
            Icon: ICONS.CreateUser,
            path: '/users/create',
          },
        ]
      },
      {
        title: t('Distributor management'),
        children: [
          {
            title: t('Distributors'),
            Icon: ICONS.Distributor,
            path: '/distributors/list'
          }
        ]
      }
    ],
  });
  return (
    <Router routes={mount(routes)}>
      <AppTreeViewProvider value={appTreeView}>
        <Layout renderNotFound={(err) => NotFound(err, t)}>
          <Suspense fallback={null}>
            <View />
          </Suspense>
        </Layout>
      </AppTreeViewProvider>
    </Router>
  );
};
