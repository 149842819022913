import { Instance, types } from "mobx-state-tree";

import { createContext, useContext } from "react";

export const AppSubMenuItem = types
  .model({
    title: types.string,
    secondary: types.maybe(types.string),
    Icon: types.frozen(),
    path: types.string,
  })
  .views((self) => ({
    isSelected(pathname: string) {
      return pathname === self.path;
    },
  }));

export const AppMainMenuItem = types.model({
  title: types.string,
  secondary: types.maybe(types.string),
  Icon: types.frozen(),
  children: types.array(AppSubMenuItem),
});

export const AppTreeView = types.model({
  items: types.array(AppMainMenuItem),
});

export interface AppTreeViewInstance extends Instance<typeof AppTreeView> {}

const AppTreeViewStoreContext = createContext<null | AppTreeViewInstance>(null);

export const AppTreeViewProvider = AppTreeViewStoreContext.Provider;

export function useAppTreeViewStore() {
  const store = useContext(AppTreeViewStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}
