import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '../globals';
import { AccessTokenLoader } from './AccessTokenLoader';
import { IsUserLogged } from './IsUserLogged';

export const AuthenticationProvider = (props: {
  children: React.ReactNode;
}) => {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      audience={AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      scope="read:current_user update:current_user_metadata"
      useRefreshTokens={true}
    >
      <IsUserLogged>
        <AccessTokenLoader>{props.children}</AccessTokenLoader>
      </IsUserLogged>
    </Auth0Provider>
  );
};
