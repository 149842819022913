import {
  FetchResult,
  MutationHookOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';
import { Mutation } from './types';

export function useM<VariablesT>(
  mut: Mutation<VariablesT>,
  mutationOptions: MutationHookOptions
): [(variables?: VariablesT) => Promise<FetchResult>, MutationResult] {
  const [mutFunction, others] = useMutation(mut.query, mutationOptions);

  return [
    (variables?: VariablesT) =>
      mutFunction({ variables }),
    others,
  ];
}
