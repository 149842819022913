import ListAlt from '@mui/icons-material/ListAlt';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import Handyman from '@mui/icons-material/Handyman';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Store from '@mui/icons-material/Store';
import Check from '@mui/icons-material/Check';
import Search from '@mui/icons-material/Search';
import Account from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import Link from '@mui/icons-material/Link';
import LinkOff from '@mui/icons-material/LinkOff';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Add from '@mui/icons-material/Add';
import Download from '@mui/icons-material/Download';
import AddBusiness from '@mui/icons-material/AddBusiness';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Close from '@mui/icons-material/Close';
import Save from '@mui/icons-material/Save';
import Upload from '@mui/icons-material/Upload';
import History from '@mui/icons-material/History';
import Home from '@mui/icons-material/Home';
import People from '@mui/icons-material/People';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Sync from '@mui/icons-material/Sync';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VpnKey from '@mui/icons-material/VpnKey';
import PersonRemove from '@mui/icons-material/PersonRemove';
import MyLocation from '@mui/icons-material/MyLocation';
import BugReport from '@mui/icons-material/BugReport';
import HealthAndSafety from '@mui/icons-material/HealthAndSafety';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Message from '@mui/icons-material/Message';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Send from '@mui/icons-material/Send';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import HelpOutline from '@mui/icons-material/HelpOutline'
import AccessTime from '@mui/icons-material/AccessTime';
import Settings from '@mui/icons-material/Settings';
import Air from '@mui/icons-material/Air';
import Thermostat from '@mui/icons-material/Thermostat';
import MonitorWeight from '@mui/icons-material/MonitorWeight';
import Battery from '@mui/icons-material/BatteryFull';
import Power from '@mui/icons-material/Power'
import PowerOff from '@mui/icons-material/PowerOff'
import Error from '@mui/icons-material/Error';

export const ICONS = {
  Check,
  Search,
  MedicalDevice: ListAlt,
  SparePart: FormatListBulleted,
  Component: Handyman,
  Document: InsertDriveFile,
  Distributor: Store,
  Account,
  Logout,
  ManageAccounts,
  Assign: Link,
  Assembly: Link,
  Unassign: LinkOff,
  View: Visibility,
  Delete,
  Edit,
  Save,
  Add,
  Download,
  AddDistributor: AddBusiness,
  AddMedicalDevice: PlaylistAdd,
  AddComponent: AddCircleOutline,
  SeeAll: KeyboardArrowRightIcon,
  Cancel: Close,
  Upload,
  History,
  Home,
  Users: People,
  CreateUser: PersonAdd,
  Sync,
  VisibilityOff: VisibilityOff,
  VisibilityOn: Visibility,
  Password: VpnKey,
  RemoveUser: PersonRemove,
  Map: MyLocation,
  Incident: BugReport,
  Patient: HealthAndSafety,
  Status: MiscellaneousServicesIcon,
  Message,
  ExpandMore,
  Send,
  Order: ShoppingCart,
  Help: HelpOutline,
  Time: AccessTime,
  Settings: Settings,
  BPM: Air,
  Temperature: Thermostat,
  Pressure: MonitorWeight,
  Battery,
  Power,
  PowerOff,
  Error,
};
